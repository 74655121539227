import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';
import { Hero } from '../../components/AdminAboutPro/Angular/Hero';
import { Feature } from '../../components/AdminAboutPro/Angular/Feature';
import { Showcase } from '../../components/AdminAboutPro/Angular/Showcase';
import { Comparison } from '../../components/AdminAboutPro/Angular/Comparison';
import { About as AboutSection } from '../../components/AdminAboutPro/Angular/About';
import '../../styles/scss/style.scss' 

const AdminContrastPro = () => {

  return (
    <Layout>
      <Helmet>
        <title>Contrast Angular Admin Dashboard (Pro) - Devwares</title>
        <meta name="title" content="Contrast Angular Admin Dashboard (Pro) - Devwares" />
        <meta
          name="description"
          content="Beautifully designed, responsive admin dashboards to power your admin websites and apps. Built with today's standards and developed with the best tools"
        />
        <meta property="og:title" content="Contrast Angular Admin Dashboard (Pro) - Devwares" />
        <meta
          property="og:description"
          content="Beautifully designed, responsive admin dashboards to power your admin websites and apps. Built with today's standards and developed with the best tools"
        />
        <meta property="twitter:title" content="Contrast Angular Admin Dashboard (Pro) - Devwares" />
        <meta
          property="twitter:description"
          content="Beautifully designed, responsive admin dashboards to power your admin websites and apps. Built with today's standards and developed with the best tools"
        />
        <link rel="canonical" href="https://www.devwares.com/product/contrast-pro/" />
        <meta property="og:url" content="https://www.devwares.com/product/contrast-pro/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <div className="container">
        <Hero />
        <Feature />
        <Showcase />
        <Comparison />
        <AboutSection />
      </div>
    </Layout>
  );
};

export default AdminContrastPro;
